import "./App.css";
import { VscCopy } from "react-icons/vsc";
import { HiPhoneOutgoing, HiPhoneMissedCall, HiMicrophone, HiPause, HiStop } from "react-icons/hi";
import { GrResume } from "react-icons/gr";
import { useEffect, useState } from "react";

function App() {
  const [loader, setLoader] = useState(true);
  const [roomId, setRoomId] = useState(undefined);
  const [meetingLink, setMeetingLink] = useState(undefined);
  const [isRecording, setIsRecording] = useState(undefined);
  const [didCallStart, setDidCallStart] = useState(false);
  const [displayName, setDisplayName] = useState(undefined);
  const [participantsModerator, setParticipantsModerator] = useState(undefined);
  const [recUserID, setRecUserId] = useState(undefined);
  const [recUserIDInd, setRecUserIdInd] = useState(undefined);
  const [timer, setTimer] = useState('')
  const [timerValue, setTimerValue] = useState({h: "00", m: "00", s: "00"})
  const [participantsRecorder, setParticipantsRecorder] = useState(undefined);
  const [options, setOptions] = useState({
    hosts: {
      domain: "meet.jit.si",
      muc: "conference.meet.jit.si",
      focus: "focus.meet.jit.si",
    },
    externalConnectUrl: "https://meet.jit.si/http-pre-bind",
    enableP2P: true,
    p2p: {
      enabled: true,
      preferH264: true,
      disableH264: true,
      useStunTurn: true,
    },
    useStunTurn: true,
    bosh: `https://meet.jit.si/http-bind?room=liveroom`,
    websocket: "wss://meet.jit.si/xmpp-websocket",
    clientNode: "http://jitsi.org/jitsimeet",
  });
  const startAllRecording = () => {
    const ids = [];

    for (let index = 0; index < participantsModerator.length; index++) {
      ids.push(participantsModerator[index]._id);
    }
    for (let index = 0; index < participantsRecorder.length; index++) {
      if (ids.indexOf(participantsRecorder[index]) < 0) {
        ids.push(participantsRecorder[index]._id);
      }
    }
    for (let index = 0; index < ids.length; index++) {
      window.startIndividualRecording(ids[index]);
    }
    window.startIndividualRecording('local');

  };
  const pauseAllRecording = () => {
    const ids = [];
    window.pauseIndividualRecording('local');

    for (let index = 0; index < participantsModerator.length; index++) {
      ids.push(participantsModerator[index]._id);
    }
    for (let index = 0; index < participantsRecorder.length; index++) {
      if (ids.indexOf(participantsRecorder[index]) < 0) {
        ids.push(participantsRecorder[index]._id);
      }
    }
    for (let index = 0; index < ids.length; index++) {
      window.pauseIndividualRecording(ids[index]);
    }
  };

  function refreshPage() {
    window.location.reload();
  }
  const resumeAllRecording = () => {
    const ids = [];
    window.resumeIndividualRecording("local");

    for (let index = 0; index < participantsModerator.length; index++) {
      ids.push(participantsModerator[index]._id);
    }
    for (let index = 0; index < participantsRecorder.length; index++) {
      if (ids.indexOf(participantsRecorder[index]) < 0) {
        ids.push(participantsRecorder[index]._id);
      }
    }
    for (let index = 0; index < ids.length; index++) {
      window.resumeIndividualRecording(ids[index]);
    }
  };
  const stopAllRecording = () => {
    const ids = [];
    window.stopIndividualRecording('local');

    for (let index = 0; index < participantsModerator.length; index++) {
      ids.push(participantsModerator[index]._id);
    }
    for (let index = 0; index < participantsRecorder.length; index++) {
      if (ids.indexOf(participantsRecorder[index]) < 0) {
        ids.push(participantsRecorder[index]._id);
      }
    }
    for (let index = 0; index < ids.length; index++) {
      window.stopIndividualRecording(ids[index], "ainnotate");
    }
  };

  const generateName = () => {
    const firstName = "mpc"
    return firstName;
  };

  const generateRoomId = () => {
    const RoomId = "ashir"
    return RoomId;
  }

  // const startTimer = () => {
  //   let timer = setInterval(() => {
  //       setTimerValue((prevState) => ({...prevState, s: parseInt(prevState.s) + 1 < 10 ? '0'+(parseInt(prevState.s) + 1) : (parseInt(prevState.s) + 1).toString()}))
  //   }, 1000);
  //   setTimer(timer)
  // }

  const startTimer = () => {
    let timer = setInterval(() => {
        setTimerValue((prevState) => {
            let newSeconds = parseInt(prevState.s) + 1;
            let newMinutes = parseInt(prevState.m);
            let newHours = parseInt(prevState.h);
            if (newSeconds >= 60) {
                newSeconds = 0;
                newMinutes += 1;
            }
            if (newMinutes >= 60) {
                newMinutes = 0;
                newHours += 1;
            }
            return {
                h: newHours < 10 ? '0'+newHours : newHours.toString(),
                m: newMinutes < 10 ? '0'+newMinutes : newMinutes.toString(),
                s: newSeconds < 10 ? '0'+newSeconds : newSeconds.toString()
            };
        });
    }, 1000);
    setTimer(timer);
}
  

  const pause = () => {
    if(isRecording === "true") {
        endTimer()
    } else {
        startTimer()
    }
    // setIsPaused(!isPaused)
  }

  const endTimer = () => {
    clearInterval(timer);
  }

  useEffect(() => {
    // const name = generateName();
    // console.log("Ashirbad Data => for meeting link => ainnotatetest")
//     const RoomId = generateRoomId();
    setDisplayName("Host");
    setRoomId("jitsitest");
  }, [])

  useEffect(() => {
    const scriptToAdd = [
      "https://www.webrtc-experiment.com/MultiStreamsMixer.js",
      "https://www.WebRTC-Experiment.com/RecordRTC.js",
      "https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js",
      "https://code.jquery.com/jquery-3.5.1.min.js",
      "https://meet.jit.si/libs/lib-jitsi-meet.min.js",
      "example_user_join.js",
    ];
    for (let index = 0; index < scriptToAdd.length; index++) {
      const url = scriptToAdd[index];
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.onload = function (params) { };
      document.body.appendChild(script);
    }
    setLoader(false);
  }, []);
  useEffect(() => {
    setMeetingLink(`https://${options?.hosts?.domain}/${roomId}`);
  }, [roomId]);
  useEffect(() => {
    console.error("participatesRecorder>>>", participantsRecorder);
  }, [participantsRecorder]);
  useEffect(() => {
    // console.error("participatesModerator>>>", participantsModerator);
  }, [participantsModerator]);
  useEffect(() => {
    console.error("rec user id>>>", recUserID);
  }, [recUserID]);
  useEffect(() => {console.log("Ashirbad recording status ==>> ", isRecording)})
  return (


    <div className="App">
      <div id="multi-party-users-list" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start', padding: '20px', height: '120px'}}>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', position: 'absolute', bottom: 300}}>
        <p style={{ color: '#000000', fontWeight: 'bold', fontSize: 35 }}>{timerValue.h}:{timerValue.m}:{timerValue.s}</p>
      </div>
      <div class="container mt-4">
        <div class="row">
          {
//             didCallStart && <div className="copy-link" id="copy-link-btn" onClick={() => {navigator.clipboard.writeText(meetingLink)}}><VscCopy size={'1.8rem'} style={{fontWeight: 'bolder'}}/><p style={{margin: 0, fontWeight: 'bolder', fontSize: 18}}>Copy</p></div>
            didCallStart && <div className="copy-link" id="copy-link-btn" onClick={() => {window.ReactNativeWebView.postMessage(JSON.stringify({type:"copyMeeetingLink",meetingLink: `https://${options?.hosts?.domain}/ainnotatetest`}))}}><VscCopy size={'1.8rem'} style={{fontWeight: 'bolder'}}/><p style={{margin: 0, fontWeight: 'bolder', fontSize: 18}}>Copy</p></div>
          }
        </div>
      </div>
      <div className="controls-container">
        <div id="startCallWithLocalBtn" onClick={() => {
            setIsRecording("true")
            startTimer()
            window.startRecordingWithLocal();
          }} style={{ display: "none" }}><HiMicrophone size={'1.5rem'} /><p style={{margin: 0, fontWeight: 'bold', fontSize: 18}}>Record</p>
        </div>
        {
          isRecording === "true" ? <div id="pauseWithLocalBtn" onClick={() => {
              setIsRecording("false")
              pause()
              window.pauseRecordingWithLocal();
            }}
          ><HiPause size={'1.5rem'}/><p style={{margin: 0, fontWeight: 'bolder', fontSize: 18}}>Pause</p></div> : <div style={{display: 'none'}} id="pauseWithLocalBtn"></div>
        }
        {
          isRecording === "false" ? <div id="recordWithLocalBtn" onClick={() => {setIsRecording("true"); startTimer(); window.resumeRecordingWithLocal()}}><GrResume size={'1.5rem'}/><p style={{margin: 0, fontWeight: 'bolder', fontSize: 18}}>Resume</p></div> : <div style={{display: 'none'}} id="recordWithLocalBtn"></div>
        }
        {
          isRecording ? <div id="stoplWithLocalBtn" onClick={() => {window.stopRecordingWithLocal("ainnnotate"); endTimer();}} style={{ display: "none" }} ><HiStop size={'1.5rem'}/><p style={{margin: 0, fontWeight: 'bolder', fontSize: 18}}>Stop</p></div> : <div id="stoplWithLocalBtn" style={{ display: "none" }}></div>
        }
        
        {
          !didCallStart && <div id="start-call-btn" onClick={() => {window.startACall("ainnotatetest", displayName, options);setDidCallStart(true)}}><HiPhoneOutgoing size={'1.5rem'}/><p style={{margin: 0, fontWeight: 'bold', fontSize: 18}}>Start</p></div>
        }
        {
          didCallStart && <div id="leave-call-btn" onClick={() => {window.unload(); endTimer();refreshPage()}}><HiPhoneMissedCall size={'1.5rem'}/><p style={{margin: 0, fontWeight: 'bold', fontSize: 18}}>End</p></div>
        }
      </div>
    </div>
  );
}

export default App;